import React, { useState, useEffect } from 'react';
import logo from '../logo/white_text_logo.png';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isTransparentMenu, setIsTransparentMenu] = useState(false);


  useEffect(() => {
    // Function to scroll to the element specified in the URL hash
    const scrollToHashElement = () => {
      const hash = window.location.hash;
      if (hash) {
        const targetId = hash.substring(1); // Remove the '#' from the hash
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - 80;
          window.scrollTo({ top: targetPosition, behavior: 'smooth' });
        }
      }
    };

    scrollToHashElement();

    window.addEventListener('hashchange', scrollToHashElement);
    return () => {
      window.removeEventListener('hashchange', scrollToHashElement);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsTransparentMenu(true);
        setIsMenuOpen(false);
      } else if (window.scrollY === 0) {
        setIsTransparentMenu(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - 80;
      window.scrollTo({ top: targetPosition, behavior: 'smooth' });
      window.history.pushState(null, '', `#${targetId}`);
      setIsMenuOpen(false);
    }
  };

  return (
    <header className={`bg-gray-800 text-white py-4 fixed top-0 w-full z-10 ${isTransparentMenu ? 'bg-opacity-90' : 'bg-opacity-100'}`}>
      <div className="container mx-auto flex justify-between items-center relative">
        <a href="/">
          <img src={logo} alt="Cognita Business Bots Logo" className="h-12 rounded-lg" />
        </a>
        <div className="md:hidden">
          <button 
            onClick={() => { setIsMenuOpen(!isMenuOpen); setIsTransparentMenu(false); }} 
            className="text-white focus:outline-none"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <nav className="hidden md:flex md:items-center">
          <ul className="flex flex-row space-x-4">
            <li><a href="#vision" className="hover:text-gray-300" onClick={(e) => handleMenuClick(e, 'vision')}>Vision</a></li>
            <li><a href="#services" className="hover:text-gray-300" onClick={(e) => handleMenuClick(e, 'services')}>Services</a></li>
            <li><a href="#contact" className="hover:text-gray-300" onClick={(e) => handleMenuClick(e, 'contact')}>Contact Us</a></li>
          </ul>
        </nav>
      </div>
      {isMenuOpen && (
        <div className="relative md:hidden bg-gray-800 w-full left-0 py-2 shadow-lg">
          <nav className="flex flex-col items-center">
            <ul className="space-y-4">
              <li><a href="#vision" className="block hover:text-gray-300" onClick={(e) => handleMenuClick(e, 'vision')}>Vision</a></li>
              <li><a href="#services" className="block hover:text-gray-300" onClick={(e) => handleMenuClick(e, 'services')}>Services</a></li>
              <li><a href="#contact" className="block hover:text-gray-300" onClick={(e) => handleMenuClick(e, 'contact')}>Contact Us</a></li>
            </ul>
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;
