import React from 'react';
import CRMImage from '../images/CRM.png';


function HeroSection() {
  return (
    <div className="hero bg-blue-600 text-white text-center p-10">
      <h2 className="text-3xl font-bold">Welcome to Cognita Business Bots</h2>
      <p className="text-xl mt-2">Revolutionizing Business to Customer Communication with AI</p>
      <br />
      <div className="flex justify-center items-center">
        <img src={CRMImage} alt="Business to Customer Relationships" className="h-[15rem] bg-blend-normal rounded-lg" />
      </div>
      <button className="mt-4 bg-blue-700 px-6 py-3 rounded shadow-lg hover:bg-blue-800 transition-colors">Learn More</button>
      {/* <div class="relative mix-blend-overlay">
        <img src={AdaLovelaceImage} alt="BannerImage" class="absolute h-[70vh] lg:h-[80vh] w-full object-cover object-right opacity-30 " />
        <div class="bg-gradient-to-t from-white via-black to-black h-[70vh] lg:h-[80vh] w-full" />
      </div> */}
    </div>

  );
}

export default HeroSection;
