import React from 'react';
// import React, { useState } from 'react';
// import axios from 'axios';

function ContactForm() {
    // const [formData, setFormData] = useState({
    //     email: '',
    //     fullName: '',
    //     title: '',
    //     message: ''
    // });
    // const [submitted, setSubmitted] = useState(false);

    // const handleChange = (e) => {
    //     setFormData({ ...formData, [e.target.name]: e.target.value });
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const formURL = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScje7YzAXnI4IhCCjP0KM3vBLZvuRG2ekIgqW4v5HVDVK-vDw/formResponse';
    //     const googleFormData = new FormData();
    //     googleFormData.append('entry.2062008376', formData.email);  // Replace 'entry.xxxxx' with the actual entry ID from Google Forms
    //     googleFormData.append('entry.474382930', formData.fullName);
    //     googleFormData.append('entry.1105497611', formData.title);
    //     googleFormData.append('entry.748030859', formData.message);

    //     axios.post(formURL, googleFormData)
    //         .then(response => {
    //             console.log('Form Submitted', response);
    //             setSubmitted(true);
    //             // setTimeout(() => {
    //             //     setSubmitted(false);
    //             // }, 3000);  // Reset the submission state after 3 seconds
    //         })
    //         .catch(error => {
    //             console.error('Submission error', error);
    //         });
    // };

    return (
        <section id="contact" className="py-12 bg-gray-50">
            <div className="flex justify-center items-center">
                <iframe
                    title="Cognita Business Bots Contact Form"
                    src="https://docs.google.com/forms/d/e/1FAIpQLScje7YzAXnI4IhCCjP0KM3vBLZvuRG2ekIgqW4v5HVDVK-vDw/viewform?embedded=true" width="640" height="960" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
            </div>
            {/* <div className="container mx-auto">
                <h3 className="text-3xl font-bold text-center text-gray-800 mb-6">Contact Us</h3>
                {!submitted ? (
                    <form onSubmit={handleSubmit} className="max-w-xl mx-auto space-y-4">
                        <div>
                            <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
                            <input type="text" name="fullName" id="fullName"
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                value={formData.fullName} onChange={handleChange} />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
                            <input type="email" name="email" id="email"
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                value={formData.email} onChange={handleChange} />
                        </div>
                        <div>
                            <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
                            <input type="text" name="title" id="title"
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                value={formData.title} onChange={handleChange} />
                        </div>
                        <div>
                            <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                            <textarea name="message" id="message" rows="4"
                                required
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                value={formData.message} onChange={handleChange}></textarea>
                        </div>
                        <div>
                            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                Send Message
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className="text-center">
                        <div className="mx-auto animate-checkmark">
                            ✔
                        </div>
                        <p className="text-lg text-green-600">Message sent successfully!</p>
                    </div>
                )}
            </div> */}
        </section>
    );
}

export default ContactForm;
