import React from 'react';
import AdaLovelaceImage from '../images/Ada_Lovelace.png';

function VisionSection() {
  return (
    <section id="vision" className="bg-white text-gray-800 p-8">
      <h3 className="text-2xl font-semibold">Our Vision</h3>
      <p className="mt-2 text-lg">At Cognita Business Bots, our vision is to revolutionize business communication with customers by delivering intelligent, efficient, and personalized AI chat solutions. We aim to enhance customer experiences and streamline operations with a steadfast commitment to reliability and strength. Our innovative approach ensures that every interaction and every solution builds towards a more connected and dynamic future for businesses globally.</p>
      <br /><br />
      <div className="flex justify-center items-center">
        <img src={AdaLovelaceImage} alt="Assistance" className="h-[15rem] bg-blend-normal" />
      </div>
    </section>
  );
}

export default VisionSection;
