import React from 'react';
// import RobotHandImage from '../images/robot_hand.png';

function Testimonials() {
    return (
        <section className="bg-blue-50 p-8">
            <h3 className="text-2xl font-semibold">What Our Clients Say</h3>
            <blockquote className="mt-4 italic">"Fantastic AI solutions that transformed our business communication!" - Anonymous Client</blockquote>
            <blockquote className="mt-4 italic">"Cognita's AI solutions have revolutionized our customer interactions! Their chatbots are incredibly intuitive, handling complex queries with ease. We've seen a 40% increase in response times and a significant boost in customer satisfaction. The multi-platform integration was seamless, and the analytics provided valuable insights for our strategy. Their support team is responsive and knowledgeable. It's not just a chatbot; it's like having a tireless, intelligent assistant working 24/7. Truly a game-changer for our business!" - Anonymous Client</blockquote>
            {/* <br /><br />
            <div className="flex justify-center items-center">
                <img src={RobotHandImage} alt="Assistance" className="h-[15rem] bg-blend-normal rounded-lg" />
            </div> */}
        </section>
    );
}

export default Testimonials;
