import './App.css';
import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import VisionSection from './components/VisionSection';
import InnovationSection from './components/InnovationSection';
import ServicesSection from './components/ServicesSection';
import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App h-screen">
      <div className={`bg-gray-800 h-[80px]`}></div>
      <div className=''>
        <Header />
        <HeroSection />
        <VisionSection />
        <InnovationSection />
        <ServicesSection />
        <Testimonials />
        <ContactForm />
        <Footer />
      </div>
    </div>
  );
}

export default App;
