import React from 'react';

// function ServicesSection() {
//     return (
//         <section id="services" className="p-8">
//             <h3 className="text-2xl font-semibold">Our Services</h3>
//             <p className="mt-2 text-lg">Detailed descriptions of services like AI chatbot development, customization, and integration services.</p>
//             <br /><br />
//             <div className="flex justify-center items-center">
//                 <img src={RobotHandImage} alt="Assistance" className="h-[15rem] bg-blend-normal rounded-lg" />
//             </div>
//         </section>

//     );
// }

// export default ServicesSection;

function ServicesSection() {
  return (
    <section id="services" className="py-12 bg-gray-50">
      <div className="container mx-auto">
        <h3 className="text-4xl font-bold text-center text-gray-800 mb-10">Our Service Plans</h3>
        <div className="overflow-x-auto shadow-lg rounded-lg">
          <table className="w-full text-left table-auto">
            <thead className="text-sm text-gray-700 uppercase bg-gray-200">
              <tr>
                <th className="px-6 py-3 font-semibold">Features</th>
                <th className="px-6 py-3 font-semibold">Basic Plan</th>
                <th className="px-6 py-3 font-semibold">Professional Plan</th>
                <th className="px-6 py-3 font-semibold">Enterprise Plan</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 divide-y divide-gray-200">
              {[
                { feature: "Platform Integration", basic: "Single platform", professional: "Up to 3 platforms", enterprise: "Unlimited platforms" },
                { feature: "NLP Capabilities", basic: "Basic NLP", professional: "Advanced NLP with intent recognition", enterprise: "AI-powered continuous learning" },
                { feature: "Customizability", basic: "Standard templates", professional: "Customizable chatbot persona", enterprise: "Fully customized development" },
                { feature: "Analytics", basic: "Basic analytics and reporting", professional: "Enhanced analytics with insights", enterprise: "Real-time analytics dashboard" },
                { feature: "Support", basic: "Email (48 hours)", professional: "Priority email (24 hours)", enterprise: "24/7 support with phone and chat" },
                { feature: "CRM Integration", basic: "Not included", professional: "Basic level", enterprise: "Full integration" },
                { feature: "Multilingual Support", basic: "Not included", professional: "Up to 3 languages", enterprise: "Unlimited languages" },
                { feature: "Additional Features", basic: "-", professional: "-", enterprise: "Voice AI, advanced security, strategy sessions" },
                { feature: "Active Users", basic: "Up to 1,000 users", professional: "Up to 5,000 users", enterprise: "Unlimited users"},
                { feature: "Pricing", basic: "", professional: "", enterprise: "", comingSoon: true },
              ].map((item, index) => (
                <tr key={index} className={index % 2 ? "bg-gray-50" : ""}>
                  <td className="px-6 py-4">{item.feature}</td>
                  <td className="px-6 py-4">{item.basic} {item.comingSoon ? <span className="text-red-500 font-semibold">(Coming Soon)</span> : ''}</td>
                  <td className="px-6 py-4">{item.professional} {item.comingSoon ? <span className="text-red-500 font-semibold">(Coming Soon)</span> : ''}</td>
                  <td className="px-6 py-4">{item.enterprise} {item.comingSoon ? <span className="text-red-500 font-semibold">(Coming Soon)</span> : ''}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;
