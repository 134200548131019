import React from 'react';

function InnovationSection() {
  return (
    <section id="innovation" className="bg-gray-50 p-8">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold text-center text-gray-800">Our Innovation</h2>
        <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6">
          
          {/* Scalability */}
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-2xl text-blue-600 font-bold">Scalability</h3>
            <p className="mt-2 text-gray-700">
              Designed for growth, our solutions expand seamlessly with your business needs.
            </p>
          </div>

          {/* Security */}
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-2xl text-blue-600 font-bold">Security</h3>
            <p className="mt-2 text-gray-700">
              Prioritizing top-tier security to protect and secure your communications.
            </p>
          </div>

          {/* Integration Flexibility */}
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-2xl text-blue-600 font-bold">Integration Flexibility</h3>
            <p className="mt-2 text-gray-700">
              Our platform integrates smoothly with multiple social chat services, ensuring efficient communication across all channels.
            </p>
          </div>

        </div>
      </div>
    </section>
  );
}

export default InnovationSection;
