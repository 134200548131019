import React from 'react';

function Footer() {
    return (
        <footer className="bg-gray-900 text-gray-300 p-4 text-center">
            <p>&copy; {new Date().getFullYear()} Cognita Business Bots. All rights reserved.</p>
        </footer>

    );
}

export default Footer;
